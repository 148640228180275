import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../redux';
import * as PartnerActions from '../../redux/partnersDetailsSlice';
import { getScrollOffset, IScrollOffset, getContext, getHostSize } from '../../redux/hostContextSlice';
import PartnerDetailsCard from './partnerDetailsCard';
import PartnerDetailsOverview from './partnerDetailsOverview';
import PartnerDetailsAdditionalInfo from './partnerDetailsAdditionalInfo';
import StickyCard from '../common/stickyCard';
import Tabs from '../common/tabs';
import { ITabsProps, Tab, TabChild } from '../common/tabs';
import { isStickyCardVisible } from '../common/stickyCard';
import { runningInIframe } from '../../utils/iframeUtils';
import { Trans, useTranslation } from 'react-i18next';
import { CONTACT_ME, ADDITIONAL_INFORMATION, OVERVIEW, PARTNERS, RIGHT_ARROW, LOADING, PARTNER_NAME } from '../../constants/localization';
import { IPartnerDetails, IPartnerLocation, PartnerDetailsImp } from '../../shared/models/partnerDetailsModel';
import { IViewContext } from '../../protocol/partnersViewInboundMessages';
import { PrimaryButton, FontIcon, Spinner, SpinnerSize } from '@fluentui/react';
import { OutboundMessagesHandler } from '../../protocol/outboundMessagesHandler';
import ContactPartnerManager from '../contactPartner/contactPartnerManager';
import { getPartnerById } from '../../redux/partnersSlice';
import { IChangeUrlDetailsMessage, IChangeUrlGalleryMessage, PartnersHostMessageType } from '../../protocol/partnersHostInboundMessages';
import {
  ANALYTICS_PARTNER_CENTER_ACTIONS,
  ANALYTICS_USER_ACTIONS,
  PARTNER_CENTER_ACTIONS_PROPS_NAMES,
  trackPartnerCenterAction,
  trackUserAction
} from '../../services/analyticsService';
import { isRtl } from '../../utils/rtlUtils';
import { SYSTEM_KEY_CODES } from '../../constants/keyboardNavigation';
import ProductsBox from '../common/productsBox';
import { GetEnvConfiguration } from '../../services/configurationService';

export const OVERVIEW_TAB = 'overview';
export const ADDITIONAL_INFO_TAB = 'additionalInfo';
const MAX_PRODUCT_IN_CARD = 4;

export default function PartnerDetails() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { tab } = useParams<{ tab: string }>();
  const { contact } = useParams<{ contact: string }>();
  const { locale } = useParams<{ locale: string }>();
  const { t: translate } = useTranslation();
  // Get partner details response (can take few seconds)
  const partnerDetailsResponse = useSelector((store: RootState) => PartnerActions.getPartnerDetails(store.partnersDetails, id));
  // If we came from gallery we have the information in the tile as IPartner.
  const partnerFromTile = useSelector((store: RootState) => getPartnerById(store.partners, id));
  // Ipartner (from gallery) wrapped as partner details.
  const [partnerDetailsFromTile, setPartnerDetailsFromTile] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollOffset: IScrollOffset = useSelector((store: RootState) => getScrollOffset(store.hostContext));
  const viewContext: IViewContext = useSelector((store: RootState) => getContext(store.hostContext));
  const [selectedPartner, setSelectedPartner] = useState(null);
  const outboundMessagesHandler = new OutboundMessagesHandler();
  const isIframe: boolean = runningInIframe();
  const history = useHistory();
  const [getPartnerFromTileDone, setGetPartnerFromTileDone] = useState(false);
  const [partnerCenterActionReported, setPartnerCenterActionReported] = useState(false);

  const { height: windowHeight } = useSelector((store: RootState) => getHostSize(store.hostContext));

  useEffect(() => {
    if (partnerFromTile) {
      const partnerDetailsFromTile = new PartnerDetailsImp(partnerFromTile);
      setPartnerDetailsFromTile(partnerDetailsFromTile);
      setSelectedPartner(partnerDetailsFromTile);
    } else {
      setPartnerDetailsFromTile(null);
    }
    setGetPartnerFromTileDone(true);
  }, [partnerFromTile]);

  useEffect(() => {
    // prettier-ignore
    if (isIframe && isContact(contact)) { // lgtm[js/user-controlled-bypass]
      requestLoginIfNeeded();
    }
  }, [contact]);

  useEffect(() => {
    setIsScrolled(isStickyCardVisible('partnerstab', scrollOffset.yOffset, windowHeight));
  });

  useEffect(() => {
    dispatch(PartnerActions.fetchPartnerDetailsAsync({ id: id }));
  }, []);

  useEffect(() => {
    // No partner from tile and partner details loaded - report deep link to partner center analytis.
    if (!partnerFromTile && partnerDetailsResponse && partnerDetailsResponse.partnerDetails) {
      reportPartnerCenterDeepLinkOnce();
    }
  }, [partnerDetailsResponse]);

  const reportPartnerCenterDeepLinkOnce = () => {
    if (!partnerCenterActionReported) {
      const partnerCenterActionProps = {};
      const partner = getSelectedPartner();
      if (partner) {
        partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.EVENT_NAME] =
          ANALYTICS_PARTNER_CENTER_ACTIONS.PARTNER_DETAILS_PAGE_DEEP_LINK;
        partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_ID] = partner.universalAccountId;
        partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_LISTING_ID] = partner.id;
        partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_NAME] = partner.name;
        trackPartnerCenterAction({
          name: ANALYTICS_PARTNER_CENTER_ACTIONS.PARTNER_DETAILS_PAGE_DEEP_LINK,
          properties: partnerCenterActionProps
        });
        setPartnerCenterActionReported(true);
      }
    }
  };

  const isContact = contact => {
    return contact !== undefined && contact !== null;
  };

  const showContactDialog = (): boolean => {
    // If we are in contact mode and user logged in open contact dialog.
    if (isContact(contact)) {
      if (isIframe) {
        const userSignedIn = viewContext && viewContext.user && viewContext.user.signedIn;
        return userSignedIn;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const getSelectedPartner = (): IPartnerDetails => {
    // Partner not loaded yet
    if (!partnerDetailsResponse || !partnerDetailsResponse.partnerDetails) {
      // We have this partner tile (gallery). use it to display data untile full partner details will be loaded.
      return partnerDetailsFromTile;
    }
    let selectedPartnerResult = selectedPartner;
    if (selectedPartner) {
      // No url in pre-fetched partner - copy url to current selected partner from partner details after it was loaded.
      if (!selectedPartner.url && partnerDetailsResponse.allPartnerDetails) {
        partnerDetailsResponse.allPartnerDetails.some(partnerDetails => {
          if (partnerDetails.location.id === selectedPartner.location.id) {
            const selectedPartnerCopy = { ...selectedPartner, url: partnerDetails.url };
            selectedPartnerResult = selectedPartnerCopy;
            return true;
          }
        });
      }
      // No LinkedIn in pre-fetched partner - copy url to current selected partner from partner details after it was loaded.
      if (!selectedPartner.linkedInOrganizationProfile && partnerDetailsResponse.allPartnerDetails) {
        partnerDetailsResponse.allPartnerDetails.some(partnerDetails => {
          if (partnerDetails.location.id === selectedPartner.location.id && partnerDetails.linkedInOrganizationProfile) {
            const selectedPartnerCopy = { ...selectedPartner, linkedInOrganizationProfile: partnerDetails.linkedInOrganizationProfile };
            selectedPartnerResult = selectedPartnerCopy;
            return true;
          }
        });
      }

      return selectedPartnerResult;
    }
    return partnerDetailsResponse.partnerDetails;
  };

  const renderStickyCard = () => {
    const topOffset = runningInIframe() ? scrollOffset.yOffset : 0;
    const partner = getSelectedPartner();
    if (partner) {
      return (
        <StickyCard title={partner.name} logoURL={partner.logo} tabsProps={tabsProps} tabsChilds={tabsChilds} top={topOffset}>
          <div className="sticky-card-button">{renderContactButton()}</div>
          <div className="stickycard-favourite">{renderFavouritesButton()}</div>
        </StickyCard>
      );
    } else {
      return null;
    }
  };

  const onContactMeClick = () => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.DETAILS_CONTACT_CLICK, properties: { partnerId: id } });
    reportPartnerCenterContact();
    // prettier-ignore
    if (!isContact(contact)) { // lgtm[js/user-controlled-bypass]
      enterContactPage();
    } else {
      requestLoginIfNeeded();
    }
  };

  const reportPartnerCenterContact = () => {
    const partnerCenterActionProps = {};
    const partner = getSelectedPartner();
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.EVENT_NAME] =
      ANALYTICS_PARTNER_CENTER_ACTIONS.PARTNER_DETAILS_PAGE_CONTACT_BUTTON;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_ID] = partner.universalAccountId;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_LISTING_ID] = partner.id;
    partnerCenterActionProps[PARTNER_CENTER_ACTIONS_PROPS_NAMES.PARTNER_NAME] = partner.name;
    trackPartnerCenterAction({
      name: ANALYTICS_PARTNER_CENTER_ACTIONS.PARTNER_DETAILS_PAGE_CONTACT_BUTTON,
      properties: partnerCenterActionProps
    });
  };

  const enterContactPage = () => {
    const goToTab = tab ? tab : OVERVIEW_TAB;
    if (isIframe) {
      const changeUrlMessage: IChangeUrlDetailsMessage = {
        messageType: PartnersHostMessageType.ChangeUrlDetails,
        partnerId: id,
        tab: goToTab,
        contact: true,
        replaceUrl: true
      };
      outboundMessagesHandler.postChangeUrlDetailsMessage(changeUrlMessage);
    } else {
      const pathName = `/${locale}/partners/${id}/${goToTab}/contact`;
      history.replace(pathName);
    }
  };

  const leaveContactPage = () => {
    const goToTab = tab ? tab : OVERVIEW_TAB;
    if (isIframe) {
      const changeUrlMessage: IChangeUrlDetailsMessage = {
        messageType: PartnersHostMessageType.ChangeUrlDetails,
        partnerId: id,
        tab: goToTab,
        contact: false,
        replaceUrl: true
      };
      outboundMessagesHandler.postChangeUrlDetailsMessage(changeUrlMessage);
    } else {
      const pathName = `/${locale}/partners/${id}/${goToTab}`;
      history.replace(pathName);
    }
  };

  const requestLoginIfNeeded = () => {
    const userSignedIn = viewContext && viewContext.user && viewContext.user.signedIn;
    if (!userSignedIn) {
      outboundMessagesHandler.postLoginRequest();
    }
  };

  const onContactMeDialogDismiss = () => {
    if (contact) {
      leaveContactPage();
    }
  };

  const renderCardProducts = () => {
    return <ProductsBox products={partner.product} maxProducts={MAX_PRODUCT_IN_CARD} responsive={true}></ProductsBox>;
  };

  const renderCardButtons = () => {
    return (
      <div className="container">
        {renderContactButton()}
        {renderFavouritesButton()}
      </div>
    );
  };

  const renderContactButton = () => {
    return (
      <PrimaryButton className="contact-button-margin" name="button" type="submit" onClick={onContactMeClick}>
        <Trans i18nKey={CONTACT_ME}>Contact Me</Trans>
      </PrimaryButton>
    );
  };

  const renderFavouritesButton = () => {
    return null;
  };

  const renderOverviewTab = (partner: IPartnerDetails) => {
    return (
      <div className="overview">
        <div className="details-content-wrapper">
          <PartnerDetailsOverview partner={partner} />
        </div>
      </div>
    );
  };

  const renderAdditionalInfoTab = (partner: IPartnerDetails) => {
    return (
      <div className="overview">
        <div className="details-content-wrapper">
          <PartnerDetailsAdditionalInfo
            partner={partner}
            allLocations={partnerDetailsResponse ? partnerDetailsResponse.allLocations : [partner.location]}
          />
        </div>
      </div>
    );
  };

  const renderContactMeFlow = (partner: IPartnerDetails) => {
    if (showContactDialog()) {
      return (
        <ContactPartnerManager
          partnerId={partner.universalAccountId}
          partnerListingId={partner.id}
          partnerName={partner.name}
          partnerLogo={partner.logo}
          partnerLocation={partner.location}
          allPartnerDetails={partnerDetailsResponse ? partnerDetailsResponse.allPartnerDetails : [partner]}
          allLocations={partnerDetailsResponse ? partnerDetailsResponse.allLocations : [partner.location]}
          isRtl={isRtl()}
          loadPartnerDetails={true}
          onDismiss={onContactMeDialogDismiss}
        />
      );
    } else return null;
  };

  const getTabChildren = (partner: IPartnerDetails): TabChild[] => {
    const tabsChilds: TabChild[] = [
      {
        title: translate(OVERVIEW),
        name: OVERVIEW_TAB,
        getContent: () => renderOverviewTab(partner),
        href: '/' + locale + '/partners/' + id + '/' + OVERVIEW_TAB,
        id: id
      },
      {
        title: translate(ADDITIONAL_INFORMATION),
        name: ADDITIONAL_INFO_TAB,
        getContent: () => renderAdditionalInfoTab(partner),
        href: '/' + locale + '/partners/' + id + '/' + ADDITIONAL_INFO_TAB,
        id: id
      }
    ];

    return tabsChilds;
  };

  const getTabsProps = (): ITabsProps => {
    const tabsProps: ITabsProps = {
      defaultTab: tab ? tab : OVERVIEW_TAB,
      children: null
    };
    return tabsProps;
  };

  const onPartnerLocationChange = (location: IPartnerLocation) => {
    trackUserAction({
      name: ANALYTICS_USER_ACTIONS.DETAILS_CHANGE_PARTNER_LOCATION,
      properties: { partnerId: id, locationId: location?.id }
    });
    if (partnerDetailsResponse) {
      partnerDetailsResponse.allPartnerDetails.forEach(partnerDetails => {
        if (partnerDetails.location.id === location.id) {
          setSelectedPartner(partnerDetails);
          return;
        }
      });
    }
  };

  const breadcrumbOnClickHandler = () => {
    trackUserAction({
      name: ANALYTICS_USER_ACTIONS.DETAILS_GALLERY_BREADCRUMB_CLICK,
      properties: { partnerId: id }
    });
    // Running in iframe - use extenal website for routing.
    if (runningInIframe()) {
      const outboundMessagesHandler = new OutboundMessagesHandler();
      const changeUrlMessage: IChangeUrlGalleryMessage = {
        messageType: PartnersHostMessageType.ChangeUrlGallery,
        galleryFilter: '',
        contact: '',
        replaceUrl: false
      };
      outboundMessagesHandler.postChangeUrlGalleryMessage(changeUrlMessage);
      // standalone mode - use locale routing.
    } else {
      const pathName = `/${locale}/partners/`;
      history.push(pathName);
    }
  };

  const renderBreadcrumbs = () => {
    if (partner) {
      return (
        <div className="navigationBar" id="maincontent">
          <nav className="breadcrumb" aria-label="breadcrumb">
            <a
              className="goBackButton"
              onClick={breadcrumbOnClickHandler}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.code === SYSTEM_KEY_CODES.Enter) {
                  breadcrumbOnClickHandler();
                }
              }}
              tabIndex={0}
            >
              {translate(PARTNERS)}
            </a>
            <FontIcon aria-label={translate(RIGHT_ARROW)} iconName="ChevronRight" />
            <header aria-label={translate(PARTNER_NAME)} className="appTabButton" tabIndex={0}>
              {partner.name}
            </header>
          </nav>
        </div>
      );
    } else {
      return null;
    }
  };

  const isDiversedBusiness = (partner: IPartnerDetails): boolean => {
    return (
      (partner.daiOwnedLed && partner.daiOwnedLed.length > 0) ||
      (partner.daiThirdPartyAccreditation && partner.daiThirdPartyAccreditation.length > 0)
    );
  };

  const partner = getSelectedPartner();
  const diverseBusiness = partner && GetEnvConfiguration()?.DIVERSE_INCLUSION_ENABLED && isDiversedBusiness(partner);
  const tabsProps: ITabsProps = getTabsProps();
  const tabsChilds: TabChild[] = getTabChildren(partner);
  if (partner) {
    return (
      <div className="as-desktop-app-details" tabIndex={0}>
        <div className="spza_detail-container spza_partnerDetailContainer">
          {isScrolled ? renderStickyCard() : null}
          <div className="pdp-details" id="pdp-detailsComponent">
            {renderBreadcrumbs()}
            <div className="detail-content">
              <div className="deatil-page-content">
                {
                  <PartnerDetailsCard
                    title={partner.name}
                    location={partner.location}
                    allLocations={partnerDetailsResponse ? partnerDetailsResponse.allLocations : [partner.location]}
                    logoURL={partner.logo}
                    isDiverseBusiness={diverseBusiness}
                    onLocationChange={onPartnerLocationChange}
                  >
                    {renderCardProducts()}
                    {renderCardButtons()}
                  </PartnerDetailsCard>
                }
                <main id="partnerstab" className="pdp-tabs">
                  <Tabs defaultTab={tabsProps.defaultTab}>
                    {tabsChilds.map(tabChild => (
                      <Tab
                        title={tabChild.title}
                        key={'partnertab' + tabChild.name}
                        name={tabChild.name}
                        href={tabChild.href}
                        id={tabChild.id}
                      >
                        {tabChild.getContent()}
                      </Tab>
                    ))}
                  </Tabs>
                </main>
              </div>
            </div>
          </div>
          {renderContactMeFlow(partner)}
        </div>
      </div>
    );
  } else {
    // there is no partner yet and there is no partner from tile - loading...
    if (getPartnerFromTileDone) {
      return (
        <div className="loading-panel">
          <Spinner size={SpinnerSize.large} label={translate(LOADING)} />
        </div>
      );
      // We didn't get data from tile yet (if exist). Wait to see if we have the data.
      // Meanwhile don't display progress.
    } else return <div className="empty-partner-details" />;
  }
}
